export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CANADIAN_HOLIDAYS = {
  January: "New Year",
  February: "Valentine Sale",
  March: "Canada Music Week",
  April: "Good Friday",
  May: "Canada Fit week",
  June: "World Environment Day",
  July: "Canada Day",
  August: "Awesome August",
  September: "Labour Day",
  October: "Thanksgiving ",
  November: "Remembrance Day",
  December: "Boxing Day",
};
