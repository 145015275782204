import { servicesOffered } from "../constants/servicesOffered";

const PriceList = () => (
  <div className="bg-barber-image-11 bg-cover bg-black/60 bg-blend-darken text-white yellow-500 font-semibold">
    <table className="table-auto border-2 border-black w-full">
      <thead className=" w-full">
        <tr className="border-b border-yellow-500">
          <th className="border-b border-yellow-500 bg-black text-yellow-500 py-2 font-2xl">
            PRICE LIST{" "}
          </th>
          <th className="bg-black"></th>
        </tr>
      </thead>
      <tbody>
        {servicesOffered
          .sort((x, y) => x.price - y.price)
          .map((service) => (
            <tr key={service.id} className="border-b border-gray-500/50">
              <td className="px-2 sm:mx-4 sm:px-4 ">{service.name}</td>
              <td className="px-2 sm:mx-4 sm:px-4">$ {service.price}</td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default PriceList;
