import BookAppointment from "../components/BookAppointment";

const Experience = () => (
  <div className="md:bg-fixed lg:bg-fixed bg-cover bg-barber-shop-indoor bg-black/50 bg-blend-darken">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-yellow-400 sm:text-4xl">
          <br />
          Experience the best barber shop in town
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
          Our skilled barbers provide top-notch haircuts and grooming services
          for men of all ages. Visit us today and leave looking and feeling your
          best.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <BookAppointment />
        </div>
      </div>
    </div>
  </div>
);

export default Experience;
