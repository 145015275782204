import Poster from "../assets/img/Ermias_poster.mp4";

const PromotionalVideo = () => (
  <div className="bg-black h-full self-center">
    <video
      id="my-video"
      className="max-h-screen bg-black"
      width={"100%"}
      preload="auto"
      src={Poster}
      loop
      autoPlay
      muted
      playsInline="playsinline"
    />
  </div>
);

export default PromotionalVideo;
