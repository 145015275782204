const Footer = ({ className, ...props }) => {
  const footerClassName =
    "absolute bottom-0 left-0 right-0 p-4 bg-gray-900 " + className;
  return (
    <footer className={footerClassName} {...props}>
      <div className="max-w-[85rem] px-4 sm:px-6 mx-auto">
        <div className="grid  sm:gap-y-0 sm:flex sm:justify-between sm:items-center grid-flow-row-dense">
          <div className="flex justify-center content-evenly sm:gap-x-4 lg:gap-x-8">
            <p className="inline-flex justify-center items-center text-sm text-white">
              © All rights reserved by Paradise BarberShop.
            </p>
            <div className="inline-flex">
              <a
                className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition "
                href="https://www.facebook.com/profile.php?id=100012059377027&mibextid=9R9pXO"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
