import Stripe from "stripe";
import { useState } from "react";

const Checkout = ({ name = "Checkout", amount, className, ...props }) => {
  const [buttonName, setButtonName] = useState(name);

  async function createPaymentLink() {
    const client_secret = process.env.REACT_APP_STRIPE_CLIENT_SECRET;
    const stripe = Stripe(client_secret);

    const price = await stripe.prices.create({
      currency: "cad",
      unit_amount: amount * 100,
      product: process.env.REACT_APP_STRIPE_CUSTOM_PRICE_PRODUCT,
    });

    const paymentLinkObj = await stripe.paymentLinks.create({
      line_items: [
        {
          price: price.id,
          quantity: 1,
        },
      ],
    });
    setButtonName(name);
    window.open(paymentLinkObj.url, "_blank");
  }

  const handlePayment = async () => {
    setButtonName("Processing...");
    await createPaymentLink();
  };

  return (
    <button
      onClick={handlePayment}
      className={`rounded-md drop-shadow-lg bg-yellow-400 text-black px-4 py-3 text-sm font-semibold shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white mt-2 w-full disabled:bg-gray-300 disabled:cursor-not-allowed ${className}`}
      target="_self"
      rel="noreferrer"
      {...props}
    >
      {buttonName}
    </button>
  );
};

export default Checkout;
