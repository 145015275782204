import Navbar from "./NavbarLogo";

import BookAppointment from "../components/BookAppointment";
import PayOnlineButton from "../components/PayOnlineButton";

const Header = () => (
  <div className="w-full md:bg-fixed lg:bg-fixed bg-cover bg-barber-header-image h-full bg-black/10 bg-blend-darken relative px-6 lg:px-8">
    <Navbar />

    <div className="mx-auto py-6">
      <div className="text-center">
        <h1 className="animate-bounces text-3xl sm:text-4xl lg:text-6xl text-white drop-shadow-2xl py-2 font-bold tracking-tight text-gray-900">
          Paradise Barber Shop
        </h1>
        <div className="bg-yellow-400 p-1 mx-10"></div>
        <div className="-rotate-[2deg] hover:-rotate-[0deg] drop-shadow-lg bg-yellow-500 mx-auto px-6 p-4 my-12 w-fit">
          <p className="animate-bounce text-xl italic hover:scale-105 text-black font-bold">
            Life isn't perfect but your hair can be!
          </p>
        </div>
        <h2 className="text-2xl my-10 text-white drop-shadow-2xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
          Transform your look today
        </h2>
        <div className="flex flex-col sm:flex-row md:flex-row space-y-3 sm:space-y-0 md:space-y-0 lg:space-y-0 items-center justify-center gap-x-6">
          <BookAppointment />
          <PayOnlineButton />
        </div>
      </div>
    </div>
  </div>
);

export default Header;
