import PriceList from "./PriceList";

const FAQs = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:pt-32 lg:px-8 lg:py-20">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <PriceList />
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Frequently asked questions
              </h2>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  What services do you offer?
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  We offer haircuts, beard trims, hot towel shaves, and styling.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  How long does a typical haircut appointment take?
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  A typical haircut appointment takes around 30 - 40 minutes.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  Do I need to make an appointment?
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Appointments are recommended, it saves times.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  What are your operating hours?
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  We are open Monday to Saturday, from 9:00 AM to 7:00 PM,
                  Sunday is off.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  Do you offer discounts for seniors or students?
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Yes, we offer discounts for seniors and students. Please bring
                  a valid ID and we'll give you discount.
                </dd>
              </div>
              <p className="mt-4 text-base leading-7 text-gray-600">
                Cant find the answer you're looking for? Reach out to me on{" "}
                <a
                  href="https://wa.me/16477077571?text=Hey%20there%2C%20I%20would%20like%20to%20learn%20more%20about%20your%20service%21"
                  className="font-semibold text-green-600 hover:text-green-500 hover:underline"
                >
                  WhatsApp
                </a>
              </p>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
