const AddTip = ({ tip, handleTipClick }) => {
  const baseClassName =
    "rounded-md px-1 border border-gray-300 hover:bg-black hover:text-white hover:cursor-pointer inline-flex";

  const TIP_VALUE = [5, 10, 15, 20, 50, 100];

  return (
    <div>
      <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row">
        <p className="mb-2 mr-2">Add Tip:</p>
        <div className="flex-row grow space-x-2">
          {TIP_VALUE.map((tipValue, index) => (
            <div
              key={index}
              onClick={() => handleTipClick(tipValue)}
              className={`${baseClassName} ${
                tip === tipValue
                  ? "bg-black text-white"
                  : "text-black bg-gray-200"
              }`}
            >
              $ {tipValue}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddTip;
