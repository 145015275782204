import Logo from "../assets/img/logo-nobg.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { LeftArrow } from "../constants/svgIcons";
import { Link } from "react-router-dom";
import PayOnlineButton from "../components/PayOnlineButton";

const Booked = () => {
  return (
    <div className="relative">
      <Navbar />
      <div className="flex items-center justify-center h-screen">
        <div className="p-1 rounded shadow-lg bg-gradient-to-r from-purple-500 via-green-500 to-blue-500">
          <div className="flex flex-col items-center p-4 space-y-4 bg-white">
            <img src={Logo} alt="logo" width={"120px"} />
            <h1 className="text-4xl font-bold font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Thank You !
            </h1>
            <p className="text-center px-4">
              Your appointment has been fixed. <br />
              Please be there on time.
            </p>
            <Link
              to="/"
              className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
            >
              {LeftArrow}
              <span className="text-sm font-medium">
                {" "}
                Goto Home, Pay at store
              </span>
            </Link>
            <PayOnlineButton />
          </div>
        </div>
      </div>
      <Footer className="fixed" />
    </div>
  );
};

export default Booked;
