import Experience from "../Sections/Experience";
import Header from "../Sections/Header";
// import Membership from "../Sections/Membership";
import Services from "../Sections/Services";
import FAQs from "../Sections/FAQs";
import Location from "../Sections/Location";
import ContactPage from "../Sections/Contact";
import Gallery from "../Sections/Gallery";
import Footer from "../components/Footer";
import TopPromotionalBanner from "../components/TopPromotionalBanner;";
import PromotionalBanner from "../components/PromotionalBanner";
import PromotionalVideo from "../components/PromotionalVideo";

function Homepage() {
  return (
    <div className="relative">
      <TopPromotionalBanner />

      <div className="flex min-[100px]:max-[580px]:flex-col bg-black">
        <div className="w-full">
          <Header />
        </div>
        <PromotionalVideo />
      </div>

      <Services />
      <PromotionalBanner />
      <FAQs />
      <Gallery />
      {/* <Membership /> */}
      <Experience />
      <Location />
      <ContactPage />
      <Footer />
    </div>
  );
}

export default Homepage;
