const Location = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.242971404433!2d-80.27967775257083!3d43.538976882140695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b917f7e5cca91%3A0x8bc99c4ed77bb1b4!2sParadise%20Barbershop!5e0!3m2!1sen!2sin!4v1694628461298!5m2!1sen!2sin"
    width="100%"
    height="350"
    title="location"
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
);

export default Location;
