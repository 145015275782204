import { useContext } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../Context";

const Professional = ({ name, src, ...props }) => {
  const { professional } = useContext(MyContext);

  return (
    <Link
      to="/services"
      state={{ barber: professional }}
      className="flex-none w-1/2 md:w-1/4 lg:w-1/6  relative mb-4 hover:scale-105 hover:font-semibold hover:drop-shadow-xl hover:cursor-pointer"
      {...props}
    >
      <img
        src={src}
        alt={name}
        className="h-auto object-cover rounded-lg"
        loading="lazy"
      />
      <div className="absolute bottom-0 p-2 text-center rounded text-white bg-black w-full rounded-b-lg">
        {name}
      </div>
    </Link>
  );
};

export default Professional;
