import { useContext } from "react";
import { MyContext } from "../Context";
import { CALENDLY_APPOINTMENT } from '../constants/calendly';

const FreeAppointmentBooking = ({ state, amount, className, ...props }) => {
  const {professional} = useContext(MyContext);

  const handleBooking = () => {
    window.open(
      CALENDLY_APPOINTMENT[professional ? professional : "Ermias"],
      "_blank"
    );
  };

  return (
    <button
      onClick={handleBooking}
      className={`rounded-md drop-shadow-lg bg-green-500 text-white px-4 py-3 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white mt-2 w-full disabled:bg-gray-300 disabled:cursor-not-allowed ${className}`}
      target="_self"
      rel="noreferrer"
      {...props}
    >
      Book Appointment! (For Free!)
    </button>
  );
};

export default FreeAppointmentBooking;
