import { Link } from "react-router-dom";

const PayOnlineButton = () => {
  return (
    <Link
      to="/services"
      className="rounded-md drop-shadow-lg bg-orange-500 text-black px-4 py-3 text-sm font-semibold shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white w-full text-center"
      target="_self"
      rel="noreferrer"
    >
      Pay Online 💳
    </Link>
  );
};

export default PayOnlineButton;
