import Marquee from "react-fast-marquee";

const PromotionalBanner = () => (
  <Marquee
    className="bg-black text-lg text-yellow-400 font-bold bg-black p-2 w-full mt-2"
    direction="left"
    speed={70}
  >
    <p className="mx-4 align-center">
      Experience the finest grooming services in town
    </p>
    <p className="mx-4 "> | </p>
    <p className="mx-4 text-white">Book Appointment Now & Get Upto 25% Off</p>
    <p className="mx-4 "> | </p>
  </Marquee>
);

export default PromotionalBanner;
