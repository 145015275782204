import { Link } from "react-router-dom";
import Logo from "../assets/img/logo-nobg.png";

const Navbar = () => {
  return (
    <>
      <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-yellow-500 text-sm">
        <nav
          className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
          aria-label="Global"
        >
          <Link to="/">
            <img src={Logo} className="w-16 h-auto" alt="logo" />
          </Link>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
