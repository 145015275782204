import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MyContext } from "./Context";

import Homepage from "./Pages/Homepage";
import SelectServices from "./Pages/SelectServices";
import Booked from "./Pages/Booked";
import SelectBarber from "./Pages/SelectBarber";

function App() {
  const [amount, setAmount] = useState(0);
  const barber = ["Ermias", "Fish"];
  const [professional, setProfessional] = useState(barber[0]);

  return (
    <BrowserRouter>
      <MyContext.Provider
        value={{ amount, setAmount, professional, setProfessional, barber }}
      >
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/professional" element={<SelectBarber />} />
          <Route
            path="/services"
            element={<SelectServices professional={professional} />}
          />
          <Route path="/booked" element={<Booked />} />
        </Routes>
      </MyContext.Provider>
    </BrowserRouter>
  );
}

export default App;
