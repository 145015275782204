const Services = () => {
  const SERVICES_DESCRIPTION = {
    Haircuts: "Professional haircuts for Everyone!",
    "Beard Trimming": "Expert beard trimming and grooming services",
    "Hot Towel Shaves": "Relaxing hot towel shaves for a clean & smooth look",
    "Skin Fade": " Professional fade and line up styling",
    "Beard Clean Shave": "Expert beard shave and grooming services",
    "Hair Styling": "Hair Styling options of your choice available.",
  };

  return (
    <div className="bg-black -600 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-yellow-400 sm:text-4xl">
            <span className="text-white">Premium grooming services for</span>{" "}
            Everyone!
          </p>
          <p className="italic mt-6 text-lg leading-8 text-white">
            Choose from a range of services to enhance your style and confidence
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-2 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {Object.keys(SERVICES_DESCRIPTION).map((service) => (
              <div className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-yellow-400">
                  {service}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                  <p className="flex-auto">{SERVICES_DESCRIPTION[service]}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Services;
