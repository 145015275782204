import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { MyContext } from "../Context";

import Checkout from "../components/Checkout";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import PayToBookButton from "../components/PayToBookButton";

import { servicesOffered } from "../constants/servicesOffered";
import AddTip from "../components/AddTip";
import TotalPayable from "../components/TotalPayable";
import FreeAppointmentBooking from '../components/FreeAppointmentBooking';

const SelectServices = () => {
  const [services, updateServices] = useState([]);
  const [tip, updateTip] = useState(0);
  const { setAmount } = useContext(MyContext);
  const { state } = useLocation();

  const handleServiceCardClick = (id) => {
    const isServiceAlreadyAdded = services.filter(
      (service) => service.id === id
    );

    const selected = servicesOffered.filter((service) => service.id === id);

    if (isServiceAlreadyAdded?.length === 0) {
      updateServices([...services, ...selected]);
    } else {
      updateServices(services.filter((service) => service.id !== id));
    }
  };

  const handleTipClick = (amount) => {
    const isTipAlreadyAdded = amount === tip;

    isTipAlreadyAdded ? updateTip(0) : updateTip(amount);
  };

  const serviceProvided = servicesOffered.map((service) => {
    const serviceSelected =
      services.filter((_service) => _service.id === service.id)?.length > 0;

    return (
      <div
        onClick={() => handleServiceCardClick(service.id)}
        key={service.id}
        className={`relative px-4 py-6 mx-auto space-x-4 space-y-4 border border-gray-200 text-black text-sm font-medium  rounded-xl w-full h-32 min-w-40 min-h-32 hover:scale-105 hover:drop-shadow-xl hover:cursor-pointer ${
          serviceSelected ? "text-white bg-black" : "hover:bg-white "
        }`}
      >
        {service?.name}
        <div
          key={service.id}
          className="bg-gray-100 text-right text-black absolute right-0 bottom-5 rounded-l-lg px-2 py-1"
        >
          $ {service?.price}
        </div>
      </div>
    );
  });

  let totalCost =
    services.reduce((total, service) => total + service.price, 0) + tip;

  const handleCheckout = () => setAmount(totalCost);

  return (
    <>
      <Navbar />
      <div className="relative pb-20">
        <div className="bg-gray-50 px-8 py-20 md:px-12 lg:py-8 lg:px-32 lg:py-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* CHOOSE A SERVICE */}
            <div className="lg:col-span-7 flex flex-wrap bg-gray-50 px-4 py-4 mx-auto ">
              <h3 className="text-2xl my-6">Please choose a service</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-4 xl:grid-cols-4">
                {serviceProvided}
              </div>
            </div>

            {/* TOTAL COST */}
            <div className="relative lg:col-span-5 w-full px-4 py-4 mx-auto border-1 border-gray-400 drop-shadow-lg bg-white">
              <div className="border-gray-200 ">
                <h3 className="text-2xl my-6">Total Cost</h3>
                {services.map((service) => (
                  <div key={service.id} className="flex flex-row space-between">
                    <p key={service.id} className="grow">
                      {service?.name}
                    </p>
                    <div className="flex w-8">
                      <p className="grow">$</p>
                      <p key={"price_" + service.id} className="text-right">
                        {service?.price}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="">
                <hr className="my-6" />
                <AddTip handleTipClick={handleTipClick} tip={tip} />

                <hr className="my-6" />
                <TotalPayable totalCost={totalCost} />
              </div>
              <div className="text-right" onClick={handleCheckout}>
                <Checkout
                  name="Checkout! (Pay full Amount now!)"
                  amount={totalCost}
                  disabled={totalCost === 0}
                />
              </div>
              {/* <PayToBookButton state={state} /> */}
              <FreeAppointmentBooking state={state}/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SelectServices;
