const ContactPage = () => {
  return (
    <div className="bg-barber-contact-us bg-black/30 md:bg-fixed lg:bg-fixed bg-cover bg-blend-darken ">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center border-2 pb-8 bg-black/50">
          <h2 className="text-3xl font-bold tracking-tight text-yellow-400 sm:text-4xl">
            <br />
            Get in touch with us
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
            Have any questions or need assistance? Feel free to reach out to us.
          </p>
          <div className="animate-bounce mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://wa.me/16477077571?text=Hey%20there%2C%20I%20would%20like%20to%20learn%20more%20about%20your%20service%21"
              className="rounded-md bg-yellow-400 text-black px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Contact Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
