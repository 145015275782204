import Fish from "../assets/img/Fish.jpg";
import Ermias from "../assets/img/Ermias.jpg";

import Professional from "../components/Professional";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useContext } from "react";
import { MyContext } from "../Context";

const SelectBarber = () => {
  const { setProfessional, barber } = useContext(MyContext);

  const handleProfessionalSelection = (name) => {
    setProfessional(name);
  };

  return (
    <>
      <Navbar />
      <div className="bg-gray-50 px-4 py-10 md:px-12 lg:py-8 lg:px-32 lg:py-24 ">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* CHOOSE A BARBER */}
          <div className="lg:col-span-7 flex flex-wrap bg-gray-50 px-4 py-4 mx-auto ">
            <h3 className="text-2xl my-6">Please choose a Professional</h3>
            <div className="flex font-sans mx-auto w-full space-x-2.5 md:space-x-4">
              <Professional
                src={Ermias}
                name="Ermias"
                onClick={() => handleProfessionalSelection(barber[0])}
              />
              {/* <Professional
                src={Fish}
                name="Fish"
                onClick={() => handleProfessionalSelection(barber[1])}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectBarber;
