import Marquee from "react-fast-marquee";

import Image1 from "../assets/img/barber-images/image-1.jpg";
import Image2 from "../assets/img/barber-images/image-2.jpg";
import Image3 from "../assets/img/barber-images/image-3.jpg";
import Image4 from "../assets/img/barber-images/image-4.jpg";
import Image5 from "../assets/img/barber-images/image-5.jpg";
import Image6 from "../assets/img/barber-images/image-6.jpg";
import Image7 from "../assets/img/barber-images/image-7.jpg";
import Image8 from "../assets/img/barber-images/image-8.jpg";
import Image12 from "../assets/img/barber-images/image-12.jpg";

const Gallery = () => {
  const STOCK_IMAGES = [
    Image12,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
  ];

  return (
    <div className="bg-white">
      <div className="py-4">
        <div className="flex flex-row w-full">
          <Marquee className="flex" behaviour="alternate" direction="right">
            {STOCK_IMAGES.map((img, index) => (
              <img
                className="h-auto w-48 sm:w-60 lg:w-72 rounded-lg inline-flex mx-1.5"
                src={img}
                alt=""
                key={index}
              />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
