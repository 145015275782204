import Logo from "../assets/img/logo-nobg.png";
function NavbarLogo() {
  return (
    <header>
      <nav className="bg-transparent ">
        <div className="flex flex-wrap justify-between items-center">
          <img src={Logo} alt="logo" width={"120px"} />
        </div>
      </nav>
    </header>
  );
}

export default NavbarLogo;
