import Marquee from "react-fast-marquee";
import { CANADIAN_HOLIDAYS, MONTH_NAMES } from "../constants/canadianHolidays";

const TopPromotionalBanner = () => {
  const month = new Date().getMonth();
  return (
    <div className="z-10 top-0 left-0 w-full bg-yellow-500">
      <Marquee speed={80} autoFill direction="left">
        <div className="flex tracking-wide animate-pulse font-normal text-xl p-1 border-b-4 border-black">
          <p>{CANADIAN_HOLIDAYS[MONTH_NAMES[month]]?.toUpperCase()}</p>{" "}
          <p className="ml-1 mr-4"> SALE 🥳 !! </p>
          <p className="mx-4">Upto 25% OFF. 🤫</p>
          <p className="mx-4">BOOK NOW! 🤗</p>
        </div>
      </Marquee>
    </div>
  );
};

export default TopPromotionalBanner;
