export const servicesOffered = [
  { id: "#1", name: "REGULAR HAIRCUT", price: 25 },
  { id: "#2", name: "REGULAR HAIRCUT W/B LINE UP", price: 35 },
  { id: "#3", name: "SKIN FADE HAIRCUT", price: 30 },
  { id: "#4", name: "SKIN FADE HAIRCUT W/B LINE UP", price: 45 },
  { id: "#5", name: "LONG HAIR HAIRCUT", price: 35 },
  { id: "#6", name: "SHAMPOO + BLOW DRY", price: 20 },
  { id: "#7", name: "LINE UP + BEARD", price: 25 },
  { id: "#8", name: "EYEBROW SHAVE", price: 15 },
  { id: "#9", name: "BEARD CLEAN SHAVE", price: 20 },
  { id: "#10", name: "AFTER SHAVE SMOOTHING MASK", price: 18 },
  { id: "#11", name: "HAIR STYLING", price: 25 },
  { id: "#12", name: "HAIR DRYER", price: 10 },
  { id: "#13", name: "HAIR COLORING", price: 90 },
  { id: "#14", name: "DANDRUFF TREATMENT", price: 70 },
  { id: "#15", name: "HEAD MASSAGE", price: 20 },
  { id: "#16", name: "HAIR HIGHLIGHTING", price: 45 },
  { id: "#17", name: "BEARD COLOR", price: 70 },
  { id: "#18", name: "FACE CLEANUP", price: 30 },
  { id: "#19", name: "D-TAN FACE MASK + SCRUB", price: 50 },
  { id: "#20", name: "SKIN WHITENING FACIAL", price: 60 },
  { id: "#21", name: "FACE BLEACH", price: 40 },
];
